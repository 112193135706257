<template>
	<main>
		<div class="content">
			<div class="echart-box">
				<div class="item">
					<div class="tit">事件统计</div>
					<div class="list">
						<div class="li" v-for="(v,i) in type_list" :key="i">
							<div class="num">{{ statDetail(v) }}</div><div class="text">{{ v }}</div>
						</div>
					</div>
				</div>
			</div>
			<div class="echart-box">
				<div class="item">
					<div class="tit">事件趋势</div>
					<div class="echart" id="echart-list"></div>
				</div>
			</div>
			<div class="echart-box">
				<div class="item">
					<div class="tit">操作系统分布</div>
					<div class="echart" id="echart-os"></div>
				</div>
				<div class="item">
					<div class="tit">浏览器分布</div>
					<div class="echart" id="echart-browser"></div>
				</div>
				<div class="item">
					<div class="tit">用户地区分布</div>
					<div class="echart" id="echart-province"></div>
				</div>
			</div>
		</div>
	</main>
</template>
<script>
import {sentry} from "@/config";

import * as echarts from 'echarts/core';
import {
	TitleComponent,
	GridComponent,
	TooltipComponent,
	LegendComponent
} from 'echarts/components';
import {
	BarChart,
	LineChart,
	GaugeChart,
	PieChart
} from 'echarts/charts';
import {
	CanvasRenderer
} from 'echarts/renderers';
import {event, project} from "@/api/sentry";

echarts.use(
	[TitleComponent,GridComponent, TooltipComponent, LegendComponent, LineChart,BarChart,PieChart, GaugeChart, CanvasRenderer]
);
export default {
	data(){
		return {
			query:{},
			project:{},
			stat:{
				os:[],
				browser:[],
				province:[],
				list:[],
				list_date:[],
				count:[]
			},
			echart_list:"",
			type_list:sentry.type
		}
	},
	created() {
		this.query = this.$route.query
	},
	mounted() {
		this.getProjectDetail();
		this.getEventStat();
	},
	methods:{
		statDetail(type){
			const res = this.stat.count.find((v,i)=>{
				return v.type === type;
			})
			return res?.number || 0
		},
		getEventStat(){
			event.stat({
				id:this.query.project_id
			}).then((res)=>{
				this.stat = res;
				this.initList();
				this.initEchartOs();
				this.initEchartBrowser();
				this.initEchartProvince();
			})
		},
		getProjectDetail(){
			project.get({
				id:this.query.project_id
			}).then((res)=>{
				this.project = res.detail;
			})
		},
		initList(){
			const chartDom = document.getElementById('echart-list');
			const myChart = echarts.init(chartDom);

			const series = [];
			this.type_list.map((v,i)=>{
				const data = [];
				this.stat.list.map((item,index)=>{
					const res = item.stat.find((vv,ii)=>{
						return vv.type === v;
					})
					data.push(res?.number || 0)
				})
				series.push({
					name: v,
					type: 'line',
					smooth: true,
					data: data,
				});
			})

			const bgPatternImg = new Image();
			bgPatternImg.src = require('@/assets/sentry/image/bg2.png')
			const option = {
				backgroundColor: {
					image: bgPatternImg,
					repeat: 'repeat'
				},
				tooltip: {
					trigger: 'axis'
				},
				legend: {
					type:"scroll",
					top:"bottom",
				},
				grid: {
					top: '10%',
					left: '3%',
					right: '3%',
					bottom: '10%',
					containLabel: true
				},
				xAxis: {
					type: 'category',
					boundaryGap: false,
					data:this.stat.list_date
				},
				yAxis: {
					type: 'value'
				},
				series: series
			};
			option && myChart.setOption(option);
			window.addEventListener('resize',()=>{
				myChart.resize();
			})
			this.echart_list = myChart;
		},
		initEchartOs(){
			var chartDom = document.getElementById('echart-os');
			var myChart = echarts.init(chartDom);
			var option;

			const bgPatternImg = new Image();
			bgPatternImg.src = require('@/assets/sentry/image/bg2.png')

			var series_data = [];
			var total = 0;
			var other = 0;
			this.stat.os.map((item,index)=>{
				total = total+item.number;
				if(series_data.length <= 10){
					series_data.push({
						value:item.number,
						name:item.os
					})
				}else{
					other = other+item.number;
				}
			})
			if(other > 0){
				series_data.push({
					value:other,
					name:'其他'
				})
			}
			option = {
				backgroundColor: {
					image: bgPatternImg,
					repeat: 'repeat'
				},
				grid: {
					left: '0',
					right: '0',
					bottom: '0',
					top: '50px',
					containLabel: true
				},
				tooltip: {
					trigger: 'item',
					formatter:(params)=>{
						return params.name+': '+params.value+'次（'+(params.value/total*100).toFixed(2)+'%）';
					}
				},
				legend: {
					type: 'scroll',
					orient: 'vertical',
					left: 10,
					top: 'center',
					bottom: 20,
				},
				series: [
					{
						name: '操作系统',
						type: 'pie',
						radius: '50%',
						data: series_data,
						itemStyle: {
							opacity: 0.7,
							borderWidth: 3,
							borderColor: '#235894'
						}
					}
				]
			};

			option && myChart.setOption(option);
			window.addEventListener('resize',()=>{
				myChart.resize();
			})
		},
		initEchartBrowser(){
			var chartDom = document.getElementById('echart-browser');
			var myChart = echarts.init(chartDom);
			var option;

			const bgPatternImg = new Image();
			bgPatternImg.src = require('@/assets/sentry/image/bg2.png')

			var series_data = [];
			var total = 0;
			var other = 0;
			this.stat.browser.map((item,index)=>{
				total = total+item.number;
				if(series_data.length <= 10){
					series_data.push({
						value:item.number,
						name:item.browser
					})
				}else{
					other = other+item.number;
				}
			})
			if(other > 0){
				series_data.push({
					value:other,
					name:'其他'
				})
			}

			option = {
				backgroundColor: {
					image: bgPatternImg,
					repeat: 'repeat'
				},
				grid: {
					left: '0',
					right: '0',
					bottom: '0',
					top: '50px',
					containLabel: true
				},
				tooltip: {
					trigger: 'item',
					formatter:(params)=>{
						return params.name+': '+params.value+'次（'+(params.value/total*100).toFixed(2)+'%）';
					}
				},
				legend: {
					type: 'scroll',
					orient: 'vertical',
					left: 10,
					top: 'center',
					bottom: 20,
				},
				series: [
					{
						name: '浏览器',
						type: 'pie',
						radius: '50%',
						data: series_data,
						itemStyle: {
							opacity: 0.7,
							borderWidth: 3,
							borderColor: '#235894'
						}
					}
				]
			};

			option && myChart.setOption(option);
			window.addEventListener('resize',()=>{
				myChart.resize();
			})
		},
		initEchartProvince(){
			var chartDom = document.getElementById('echart-province');
			var myChart = echarts.init(chartDom);
			var option;

			const bgPatternImg = new Image();
			bgPatternImg.src = require('@/assets/sentry/image/bg2.png')

			var series_data = [];
			var total = 0;
			var other = 0;
			this.stat.province.map((item,index)=>{
				item.province = item.province ? item.province : '其他'
				total = total+item.number;
				if(series_data.length <= 10){
					series_data.push({
						value:item.number,
						name:item.province
					})
				}else{
					other = other+item.number;
				}
			})
			if(other > 0){
				series_data.push({
					value:other,
					name:'其他'
				})
			}

			option = {
				backgroundColor: {
					image: bgPatternImg,
					repeat: 'repeat'
				},
				grid: {
					left: '0',
					right: '0',
					bottom: '0',
					top: '50px',
					containLabel: true
				},
				tooltip: {
					trigger: 'item',
					formatter:(params)=>{
						return params.name+': '+params.value+'次（'+(params.value/total*100).toFixed(2)+'%）';
					}
				},
				legend: {
					type: 'scroll',
					orient: 'vertical',
					left: 10,
					top: 'center',
					bottom: 20,
				},
				series: [
					{
						name: '省份',
						type: 'pie',
						radius: '50%',
						data: series_data,
						itemStyle: {
							opacity: 0.7,
							borderWidth: 3,
							borderColor: '#235894'
						}
					}
				]
			};

			option && myChart.setOption(option);
			window.addEventListener('resize',()=>{
				myChart.resize();
			})
		}
	}
}
</script>
<style lang="scss" scoped>
.echart-box{display: flex;margin-top: 15px;background: #fff;padding: 15px;box-shadow: 0 0 20px 5px rgba(0,0,0,.1);
	.item{flex: 1;margin-right: 15px;
		.tit{font-weight: bold;}
		.echart{height:300px;margin-top: 15px;}
		.echart#echart-list{height:500px;}
		.list{display: flex;flex-wrap: wrap;margin-top: 15px;
			.li{width: 20%; text-align: center;margin-bottom: 15px;
				.num{font-size: 40px;font-weight: bold;color:$admin_main;}
				.text{font-size: 14px;}
			}
		}
	}
	.item:nth-last-child(1){margin-right: 0;}
}
</style>
